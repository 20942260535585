<template>
  <div class="tabs">
    <h2 class="tabs__title">Tabs</h2>
    <h3 class="tabs__subtitle">Tabs Router Navigation</h3>
    <div class="tabs__content">
      <TabsNavigation :tabs="tabsListRouter" />
    </div>
    <h3 class="tabs__subtitle">Tabs Content</h3>
    <div class="tabs__content">
      <TabsContent :tabs="tabsList" v-model="currentTab" />
      <div class="tabs__pages">
        <div v-if="currentTab === 'TabDefault'">TabDefault</div>
        <div v-if="currentTab === 'Tab1'">Tab1</div>
        <div v-if="currentTab === 'Tab2'">Tab2</div>
        <div v-if="currentTab === 'Tab3'">Tab3</div>
      </div>
    </div>
  </div>
</template>

<script>
import TabsNavigation from "@/modules/UiKit/components/Tabs/TabsList";
import TabsContent from "@/modules/UiKit/components/Tabs/TabsContent";

export default {
  name: "TabsPage",
  mixins: [],
  props: [],
  components: { TabsNavigation, TabsContent },
  data() {
    return {
      tabsListRouter: [
        {
          label: "TabDefault",
          path: "UIComponentsTabs",
          index: 0
        },
        {
          label: "Tab1",
          path: "UIComponentsTabs1",
          index: 1,
          count: null
        },
        {
          label: "Tab2",
          path: "UIComponentsTabs2",
          index: 2,
          count: 5
        },
        {
          label: "Tab3",
          path: "UIComponentsTabs3",
          index: 3,
          count: 10
        }
      ],
      currentTab: "Tab2",
      tabsList: [
        {
          label: "TabDefault",
          value: "TabDefault"
        },
        {
          label: "Tab1",
          value: "Tab1",
          count: null
        },
        {
          label: "Tab2",
          value: "Tab2",
          count: 5
        },
        {
          label: "Tab3",
          value: "Tab3",
          count: 10
        }
      ]
    };
  },
  mounted() {},
  methods: {
    changeTabContent(value) {
      this.currentTab = value;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.tabs {
  &__title {
    margin: 0 0 24px;
    color: $text-default;
  }

  &__subtitle {
    margin: 0 0 16px;
    color: $text-default;
  }

  &__content {
    margin: 24px 0 48px;
  }

  &__description {
    margin: 24px 0;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;
  }

  &__pages {
    margin: 16px 0 0;
    padding: 16px;
    border: 1px solid $border-disabled;
    border-radius: 4px;
  }
}
</style>
